import { API, graphqlOperation, Auth } from "aws-amplify"
import { listProgresss } from "../graphql/queries"
import videosData from "../content/videos.json"
import { navigate } from "gatsby"

export const appendUserProgress = (progressNew: UserProgress) => {
  if (typeof (Storage) === "undefined") return;
  const progress = getUserProgress()
  progress.push(progressNew)
  setUserProgress(progress)
}


export const setUserProgress = (progressAll: UserProgress[]) => {
  if (typeof (Storage) === "undefined") return;
  const progressStr = JSON.stringify(progressAll);
  window.localStorage.setItem("progress", progressStr);
}

export const getUserProgress = () => {
  try {
    let progress: UserProgress[] = []
    if (typeof (Storage) === "undefined") return progress

    const progressStr = window.localStorage.getItem("progress");
    if (!progressStr) return progress
    try {
      progress = JSON.parse(progressStr)
      return progress;
    } catch {
      window.localStorage.setItem("progress", "");
    }

    // const userProgress = await API.graphql(graphqlOperation(listProgresss, { limit: 200 }))
    // console.log(userProgress)
    // return userProgress.data.listProgresss.items as UserProgress[]

  } catch (err) {
    console.error(err)
  }
}

export const getCurrentUser = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser()
    if (!user) throw new Error("not signed in")
    return user
  } catch (err) {
    return null
  }
}

export const getVideoSlug = (moduleId: number, videoName: string) =>
  `${moduleId}_${videoName.toLowerCase().replace(/\s+/g, "-")}`.replace(/\(|\)|\[|\]/g, "")

export const lastUserProgress = (userProgress: UserProgress[]) =>
  userProgress
    .sort((a, b) => {
      if (a.moduleId !== b.moduleId) return a.moduleId - b.moduleId
      return a.videoOrder - b.videoOrder
    })
    .reverse()[0]

export const getNextVideo = (moduleId: number, videoOrder: number) => {
  const sortedVids = videosData.sort((a, b) => {
    if (a.moduleId !== b.moduleId) return a.moduleId - b.moduleId
    return a.videoOrder - b.videoOrder
  })
  const currentIndex = sortedVids.findIndex(vid => {
    return vid.moduleId === moduleId && vid.videoOrder === videoOrder
  })
  const nextIndex = currentIndex === sortedVids.length - 1 ? 0 : currentIndex + 1
  return sortedVids[nextIndex]
}

export const userIsInGroup = (groupName: string) => {
  return new Promise(async (res, rej) => {
    const user = await getCurrentUser()
    if (!user) {
      navigate("/sign-in")
      return
    }
    user.getSession((err, session) => {
      try {
        if (err) throw err
        const groups = session.getIdToken().payload["cognito:groups"]
        if (!!groups) {
          if (groups.includes(groupName)) res(true)
          return
        }
        res(false)
      } catch (err) {
        console.error(err)
        rej(err)
      }
    })
  })
}
